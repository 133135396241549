.uploadFileContent
    position: relative
    background: #41479B 0% 0% no-repeat padding-box
    display: flex
    flex-direction: column
    justify-content: center
    max-height: 400px
    overflow: hidden
    &.uploaded
        & .ant-upload.ant-upload-drag
            border: transparent
        & .ant-upload-drag-container
           vertical-align: top !important
    &--img
        width: 100%
        object-fit: fill
        padding: 20px
        height: 334px
    &--infoBox
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background:  #191738 0% 0% no-repeat padding-box
        border-radius: 0 0 2px 2px
        padding: 16px 18px
        &--title
            text-align: left
            font: 14px/18px "SF Pro Display Regular" 
            letter-spacing: 0
            color: #ffffff
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            width: 235px
        &--btnGroup
            & button
                background: transparent
                border: transparent
                padding: 5px
                cursor: pointer
                & .text
                    display: none
                &:hover,&:active,&:focus
                   background: transparent




    & .ant-upload.ant-upload-drag .ant-upload
        padding: 0
        background: #41479B 0% 0% no-repeat padding-box
    .ant-upload-drag-container
        background: #41479B 0% 0% no-repeat padding-box
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
