@font-face 
	font-family: "iconfont"
	src: url("@assets/fonts/svgFonts/fonts/iconfont.eot?#iefix") format("embedded-opentype")
	src: url("@assets/fonts/svgFonts/fonts/iconfont.woff2") format("woff2")
	src: url("@assets/fonts/svgFonts/fonts/iconfont.woff") format("woff")

.icon 
	line-height: 1


.icon:before 
	font-family: iconfont !important
	font-style: normal
	font-weight: normal !important
	vertical-align: top


.icon-10clubs:before 
	content: "\f101"

.icon-10diamonds:before 
	content: "\f102"

.icon-10hearts:before 
	content: "\f103"

.icon-10spades:before 
	content: "\f104"

.icon-1clubs:before 
	content: "\f105"

.icon-1diamonds:before 
	content: "\f106"

.icon-1hearts:before 
	content: "\f107"

.icon-1spades:before 
	content: "\f108"

.icon-2clubs:before 
	content: "\f109"

.icon-2diamonds:before 
	content: "\f10a"

.icon-2hearts:before 
	content: "\f10b"

.icon-2spades:before 
	content: "\f10c"

.icon-3clubs:before 
	content: "\f10d"

.icon-3diamonds:before 
	content: "\f10e"

.icon-3hearts:before 
	content: "\f10f"

.icon-3spades:before 
	content: "\f110"

.icon-4clubs:before 
	content: "\f111"

.icon-4diamonds:before 
	content: "\f112"

.icon-4hearts:before 
	content: "\f113"

.icon-4spades:before 
	content: "\f114"

.icon-5clubs:before 
	content: "\f115"

.icon-5diamonds:before 
	content: "\f116"

.icon-5hearts:before 
	content: "\f117"

.icon-5spades:before 
	content: "\f118"

.icon-6clubs:before 
	content: "\f119"

.icon-6diamonds:before 
	content: "\f11a"

.icon-6hearts:before 
	content: "\f11b"

.icon-6spades:before 
	content: "\f11c"

.icon-7clubs:before 
	content: "\f11d"

.icon-7diamonds:before 
	content: "\f11e"

.icon-7hearts:before 
	content: "\f11f"

.icon-7spades:before 
	content: "\f120"

.icon-8clubs:before 
	content: "\f121"

.icon-8diamonds:before 
	content: "\f122"

.icon-8hearts:before 
	content: "\f123"

.icon-8spades:before 
	content: "\f124"

.icon-9clubs:before 
	content: "\f125"

.icon-9diamonds:before 
	content: "\f126"

.icon-9hearts:before 
	content: "\f127"

.icon-9spades:before 
	content: "\f128"

.icon-Aclubs:before 
	content: "\f129"

.icon-Adiamonds:before 
	content: "\f12a"

.icon-Ahearts:before 
	content: "\f12b"

.icon-Aspades:before 
	content: "\f12c"

.icon-Jclubs:before 
	content: "\f12d"

.icon-Jdiamonds:before 
	content: "\f12e"

.icon-Jhearts:before 
	content: "\f12f"

.icon-Jspades:before 
	content: "\f130"

.icon-Kclubs:before 
	content: "\f131"

.icon-Kdiamonds:before 
	content: "\f132"

.icon-Khearts:before 
	content: "\f133"

.icon-Kspades:before 
	content: "\f134"

.icon-Qclubs:before 
	content: "\f135"

.icon-Qdiamonds:before 
	content: "\f136"

.icon-Qhearts:before 
	content: "\f137"

.icon-Qspades:before 
	content: "\f138"

