.customTab
  & .ant-tabs-nav
    margin: 0
    & .ant-tabs-nav-wrap
      & .ant-tabs-nav-list
        & .ant-tabs-tab
          padding: 0
          width: 90px
          & .ant-tabs-tab-btn
            width: 90px
            & img
              width: 90px