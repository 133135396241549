.cards-list
  list-style: none
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: auto
  margin: auto
  flex-direction: row
  flex-wrap: wrap
  &.reverse
    flex-direction: row-reverse
  &.banker-cards
    & .cards-list-item
      &:nth-child(1)
        order: 2
        justify-content: flex-start
      &:nth-child(2)
        order: 1
        justify-content: flex-end
      &:nth-child(3)
        order: 3
  &-item
    margin: 5px
    width: calc(50% - 10px)
    display: flex
    &:nth-child(1)
      justify-content: flex-end
    &:nth-child(2)
      justify-content: flex-start
    &:nth-child(3)
      display: flex
      justify-content: center
      width: 100%
      transform: rotate(90deg) translate(0vh, -3vw)
      height: 20vh
    &.disabled
      pointer-events: none
      opacity: 0.5
    & .card
      height: 17vw
      width: 12vw

      & .flip-card
        width: 100%
        height: 100%
        perspective: 1000px

      & .flip-card-inner
        position: relative
        width: 100%
        height: 100%
        transition: transform 0.6s
        transform-style: preserve-3d

      & .flip-card.active .flip-card-inner
        transform: rotateY(180deg)

      & .flip-card-front, .flip-card-back
        position: absolute
        width: 100%
        height: 100%
        -webkit-backface-visibility: hidden
        backface-visibility: hidden

      & .flip-card-front,  & .flip-card-back
        & img
          width: 100%
          height: 100%
          object-fit: cover

      & .flip-card-back
        transform: rotateY(180deg)
        & .cardIcon
          font-size: 17vw
          height: 100%
          position: absolute
          top: 0
          box-shadow: none
          background: transparent
