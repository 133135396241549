.inputTable
  background: #F8F8FA 0 0 no-repeat padding-box
  margin: 0 24px 24px 24px
  padding: 0 24px
  & table, th, td
    border-collapse: collapse
  & table
    & tr td
      padding: 8px
      vertical-align: middle
      &:not(:first-child)
        vertical-align: top
      &:first-child
        padding: 0 16px 0 0
      &:last-child
        padding: 8px 0 8px 8px
      & .ant-form-item
        margin-bottom: 0
  &.dice-inputs, &.token-inputs
    & .ant-form-item-explain
      display: none !important


.clientsForm
  & .ant-tabs.ant-tabs-top
    & .ant-tabs-nav
      display: none
  & .disableError
    & .ant-form-item-explain.ant-form-item-explain-error
      display: none
    &.ant-form-item-has-error .ant-input
      border: 1px solid #d9d9d9

