.header
    &-account
        padding: 0 24px
        display: flex
        align-items: center
        justify-content: center
        height: auto  !important
        & .logout-text
            margin: 0
            cursor: pointer
            color: $project-global-color
        & .logout
            position: relative
            background: #41479B 0 0 no-repeat padding-box
            width: 30px
            height: 30px  !important
            border-radius: 100%
            align-items: center
            justify-content: center
            cursor: pointer
           