.custom-card-list
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  & .cardIcon
    margin: 8px
    width: 120px
    height: 160px
    cursor: pointer
    &.active
      box-shadow: 1px 1px 2px #002766
      background: rgba(#1890ff, 0.5) 0 0 no-repeat padding-box
    &:before
      font-size: 160px