.players-popup
    .loading-list.ant-list-sm .ant-list-item div
        height: 14px
        width: 100%
        background: #F2F2F7 0 0 no-repeat padding-box
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
    .ant-modal-header
        border-bottom: 1px solid #DCDCE4

        .ant-modal-title
            color: #41479B
            font-size: 20px
    .ant-list-bordered
        border: none
    .ant-modal-body
        max-height: 60vh
        padding-top: 6px
        overflow-y: auto

    .ant-list-bordered.ant-list-sm .ant-list-item
        padding-top: 9px
        padding-bottom: 9px
        padding-left: 0
        border-bottom: 1px solid #DCDCE4
        cursor: pointer
    