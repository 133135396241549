*,html,body
  margin: 0
  padding: 0
  box-sizing: border-box
  &:not(input)
    user-select: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
  &:hover,&:active,&:focus
    outline: 0
    box-shadow: none

.content
  display: flex
  flex-direction: column
  height: 100%

.link
  cursor: pointer
  text-align: left
  text-decoration: underline
  font: 14px/19px "Red Hat Display Medium"
  letter-spacing: 0
  color: #191738
  opacity: 1

body
  overflow: hidden
.site-layout
  height: calc(100vh - 48px)
  overflow-y: scroll



.ant-form-item.without-star .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
  display: none



.cardIcon
  line-height: 1
  background-color: #eaeaec
  border-radius: 10%
  box-shadow: 1px 1px 2px #000000
  width: 100%
  height: 100%
  font-size: 55px
  position: relative
  display: block


.cardIcon:before
  font-family: iconfont !important
  font-style: normal
  font-weight: normal !important
  vertical-align: top
  position: absolute
  top: 50%
  left: 50%
  width: 100%
  height: 100%
  transform: translate(-50%, -50%)

.icon-undefined:before
  content: ""


.icon-10spades:before
  color: rgb(0 0 0)

.icon-10clubs:before
  color: rgb(0 0 0)

.icon-10hearts:before
  color: rgb(230, 19, 10)

.icon-10diamonds:before
  color: rgb(230, 19, 10)

.icon-1spades:before
  color: rgb(0 0 0)

.icon-1clubs:before
  color: rgb(0 0 0)

.icon-1hearts:before
  color: rgb(230, 19, 10)

.icon-1diamonds:before
  color: rgb(230, 19, 10)

.icon-2spades:before
  color: rgb(0 0 0)

.icon-2clubs:before
  color: rgb(0 0 0)

.icon-2hearts:before
  color: rgb(230, 19, 10)

.icon-2diamonds:before
  color: rgb(230, 19, 10)

.icon-3spades:before
  color: rgb(0 0 0)

.icon-3clubs:before
  color: rgb(0 0 0)

.icon-3hearts:before
  color: rgb(230, 19, 10)

.icon-3diamonds:before
  color: rgb(230, 19, 10)

.icon-4spades:before
  color: rgb(0 0 0)

.icon-4clubs:before
  color: rgb(0 0 0)

.icon-4hearts:before
  color: rgb(230, 19, 10)

.icon-4diamonds:before
  color: rgb(230, 19, 10)

.icon-5spades:before
  color: rgb(0 0 0)

.icon-5clubs:before
  color: rgb(0 0 0)

.icon-5hearts:before
  color: rgb(230, 19, 10)

.icon-5diamonds:before
  color: rgb(230, 19, 10)

.icon-6spades:before
  color: rgb(0 0 0)

.icon-6clubs:before
  color: rgb(0 0 0)

.icon-6hearts:before
  color: rgb(230, 19, 10)

.icon-6diamonds:before
  color: rgb(230, 19, 10)

.icon-7spades:before
  color: rgb(0 0 0)

.icon-7clubs:before
  color: rgb(0 0 0)

.icon-7hearts:before
  color: rgb(230, 19, 10)

.icon-7diamonds:before
  color: rgb(230, 19, 10)

.icon-8spades:before
  color: rgb(0 0 0)

.icon-8clubs:before
  color: rgb(0 0 0)

.icon-8hearts:before
  color: rgb(230, 19, 10)

.icon-8diamonds:before
  color: rgb(230, 19, 10)

.icon-9spades:before
  color: rgb(0 0 0)

.icon-9clubs:before
  color: rgb(0 0 0)

.icon-9hearts:before
  color: rgb(230, 19, 10)

.icon-9diamonds:before
  color: rgb(230, 19, 10)

.icon-Aspades:before
  color: rgb(0 0 0)

.icon-Aclubs:before
  color: rgb(0 0 0)

.icon-Ahearts:before
  color: rgb(230, 19, 10)

.icon-Adiamonds:before
  color: rgb(230, 19, 10)

.icon-Jspades:before
  color: rgb(0 0 0)

.icon-Jclubs:before
  color: rgb(0 0 0)

.icon-Jhearts:before
  color: rgb(230, 19, 10)

.icon-Jdiamonds:before
  color: rgb(230, 19, 10)

.icon-Kspades:before
  color: rgb(0 0 0)

.icon-Kclubs:before
  color: rgb(0 0 0)

.icon-Khearts:before
  color: rgb(230, 19, 10)

.icon-Kdiamonds:before
  color: rgb(230, 19, 10)

.icon-Qspades:before
  color: rgb(0 0 0)

.icon-Qclubs:before
  color: rgb(0 0 0)

.icon-Qhearts:before
  color: rgb(230, 19, 10)

.icon-Qdiamonds:before
  color: rgb(230, 19, 10)

