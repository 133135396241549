@font-face {
  font-family: 'SF Pro Display Medium';
  src: url(@assets/fonts/SFProDisplay-Medium.eot);
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
  url(@assets/fonts/SFProDisplay-Medium.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Medium.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Medium.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}





@font-face {
  font-family: 'SF Pro Display Bold';
  src: url(@assets/fonts/SFProDisplay-Bold.eot);
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
  url(@assets/fonts/SFProDisplay-Bold.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Bold.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Bold.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}




@font-face {
  font-family: 'SF Pro Display Regular';
  src: url(@assets/fonts/SFProDisplay-Regular.eot);
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
  url(@assets/fonts/SFProDisplay-Regular.eot?#iefix) format('embedded-opentype'),
  url(@assets/fonts/SFProDisplay-Regular.woff2) format('woff2'),
  url(@assets/fonts/SFProDisplay-Regular.woff) format('woff'),
  url(@assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}


// @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700&display=swap');